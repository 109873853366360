import classNames from 'classnames';
import React from 'react';

import ZionodesLogoDarkTheme from '@icons/ZionodesLogoDarkTheme.svg';
import Zionodes from '@icons/menu/zionodes.svg';
import { useCurrentTheme, THEME } from '@src/api/local/theme';

import styles from './loader.module.sass';

type LoaderProps = {
  fullPage?: boolean;
};

export const Loader: React.FC<LoaderProps> = ({ fullPage }) => {
  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  return (
    <div
      className={classNames(styles.loaderWrapper, {
        [styles.loaderWrapperFullPage]: fullPage,
      })}
    >
      {isLightTheme ? <Zionodes /> : <ZionodesLogoDarkTheme />}
    </div>
  );
};
